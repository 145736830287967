/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Manage and maintain claim batches of encounter form records based on asset XeClaimBatch, XeClaimBatchEntry
 */

	/**
	 * Add a claim batch
	 * @see com.thrasys.xnet.erp.billing.handler.ClaimBatchHandler.doAdd()
	 * @see com.thrasys.xnet.erp.resthandler.claimbatch.ClaimBatchService.add()
	 *
	 * assetXeClaimBatch: AssetXeClaimBatch
	 * returns: AssetXeClaimBatch
	 */
	import addRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';
	import addResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';

	export const add = (assetXeClaimBatch, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/claimBatch';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeClaimBatch;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-claim-batchs-svc',
			action: 'add',
			requestSchema: addRequestSchema,
			responseSchema: addResponseSchema
		});
	};

	add.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';
	add.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';

	/**
	 * Search claim batches
	 * @see com.thrasys.xnet.erp.billing.handler.ClaimBatchHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.claimbatch.ClaimBatchService.browse()
	 *
	 * claimBatchId: string
	 * healthPlanPayerId: string
	 * active: boolean
	 * statusId: string
	 * modifiedTstampMin: Date
	 * modifiedTstampMax: Date
	 * submissionTimestampMin: Date
	 * submissionTimestampMax: Date
	 * returns: ClaimBatchFull
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull.json';

	export const browse = ({claimBatchId, healthPlanPayerId, active, statusId, modifiedTstampMin, modifiedTstampMax, submissionTimestampMin, submissionTimestampMax, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/claimBatch/browse';

		const params = {
			claimBatchId,
			healthPlanPayerId,
			active,
			statusId,
			modifiedTstampMin,
			modifiedTstampMax,
			submissionTimestampMin,
			submissionTimestampMax
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-claim-batchs-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull.json';

	/**
	 * Get details for  claim batch ID
	 * @see com.thrasys.xnet.erp.billing.handler.ClaimBatchHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.claimbatch.ClaimBatchService.getDetails()
	 *
	 * claimBatchId: string
	 * returns: ClaimBatchFull
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull.json';

	export const getDetails = ({claimBatchId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/claimBatch/details/' + claimBatchId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-claim-batchs-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull.json';

	/**
	 * Mark claim batch as in error - deactivate
	 * @see com.thrasys.xnet.erp.billing.handler.ClaimBatchHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.claimbatch.ClaimBatchService.markInError()
	 *
	 * assetXeClaimBatch: AssetXeClaimBatch
	 * returns: AssetXeClaimBatch
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';

	export const markInError = (assetXeClaimBatch, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/claimBatch/markInError';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeClaimBatch;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-claim-batchs-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeClaimBatch.json';

	/**
	 * bulk mark entries as in error 
	 * @see com.thrasys.xnet.erp.billing.handler.ClaimBatchHandler.doMarkInErrorEntries()
	 * @see com.thrasys.xnet.erp.resthandler.claimbatch.ClaimBatchService.markInErrorEntries()
	 *
	 * claimBatchEntry: ClaimBatchEntry
	 * returns: ClaimBatchEntry
	 */
	import markInErrorEntriesRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';
	import markInErrorEntriesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';

	export const markInErrorEntries = (claimBatchEntry, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/claimBatch/entries/markInError';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = claimBatchEntry;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-claim-batchs-svc',
			action: 'markInErrorEntries',
			requestSchema: markInErrorEntriesRequestSchema,
			responseSchema: markInErrorEntriesResponseSchema
		});
	};

	markInErrorEntries.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';
	markInErrorEntries.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';

	/**
	 * Add/Update entries to claim batch ID
	 * @see com.thrasys.xnet.erp.billing.handler.ClaimBatchHandler.doUpdateEntries()
	 * @see com.thrasys.xnet.erp.resthandler.claimbatch.ClaimBatchService.updateEntries()
	 *
	 * claimBatchEntry: ClaimBatchEntry
	 * returns: ClaimBatchEntry
	 */
	import updateEntriesRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';
	import updateEntriesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';

	export const updateEntries = (claimBatchEntry, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/claimBatch/addEntries';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = claimBatchEntry;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-claim-batchs-svc',
			action: 'updateEntries',
			requestSchema: updateEntriesRequestSchema,
			responseSchema: updateEntriesResponseSchema
		});
	};

	updateEntries.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';
	updateEntries.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';
