/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Methods pertaining to enterprises.
 */

	/**
	 * Search for enterprises by name and type within logged on users scope.
	 * @see com.thrasys.xnet.app.enterprise.handler.EnterpriseHandler.doBrowse()
	 * @see com.thrasys.xnet.app.resthandler.enterprise.EnterpriseService.browse()
	 *
	 * searchText: string
	 * enterpriseTypeId: string
	 * excludeEnterpriseTypeId: boolean
	 * includeNonOperational: boolean
	 * returns: AssetXeEnterpriseData
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.app.assets.AssetXeEnterpriseData.json';

	export const browse = ({searchText, enterpriseTypeId, excludeEnterpriseTypeId, includeNonOperational, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/enterprise/list';

		const params = {
			searchText,
			enterpriseTypeId,
			excludeEnterpriseTypeId,
			includeNonOperational
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enterprises-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.app.assets.AssetXeEnterpriseData.json';

	/**
	 * Return a sorted list of enterprises with specified RelationType to specified enterprise. Access to view data is not a filter.
	 * @see com.thrasys.xnet.app.enterprise.handler.EnterpriseHandler.doQueryRelatedEnterprises()
	 * @see com.thrasys.xnet.app.resthandler.enterprise.EnterpriseService.queryRelatedEnterprises()
	 *
	 * relationTypeId: string
	 * enterpriseId: string
	 * active: string
	 * returns: EnterpriseRelationResponse
	 */
	import queryRelatedEnterprisesResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.enterprisedata.EnterpriseRelationResponse.json';

	export const queryRelatedEnterprises = ({relationTypeId, enterpriseId, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/enterprise/queryRelatedEnterprises';

		const params = {
			relationTypeId,
			enterpriseId,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enterprises-svc',
			action: 'queryRelatedEnterprises',
			responseSchema: queryRelatedEnterprisesResponseSchema
		});
	};

	queryRelatedEnterprises.responseSchema = 'com.thrasys.xnet.app.xmlobjects.enterprisedata.EnterpriseRelationResponse.json';

	/**
	 * Return the list of accessible enterprises for the logged in user that have matching RelationTypeID for the loggedin enterprise.
	 * @see com.thrasys.xnet.app.enterprise.handler.EnterpriseHandler.doQueryRiskEnterprises()
	 * @see com.thrasys.xnet.app.resthandler.enterprise.EnterpriseService.queryRiskEnterprises()
	 *
	 * relationTypeId: string
	 * returns: AbstractXeEnterpriseData
	 */
	import queryRiskEnterprisesResponseSchema from '../../schemas/com.thrasys.xnet.app.xmlobjects.enterprisedata.AbstractXeEnterpriseData.json';

	export const queryRiskEnterprises = ({relationTypeId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/configData/enterprise/queryRiskEnterprises';

		const params = {
			relationTypeId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enterprises-svc',
			action: 'queryRiskEnterprises',
			responseSchema: queryRiskEnterprisesResponseSchema
		});
	};

	queryRiskEnterprises.responseSchema = 'com.thrasys.xnet.app.xmlobjects.enterprisedata.AbstractXeEnterpriseData.json';
