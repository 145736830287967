/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Define/Record/Generate correspondence about a patient to themselves and their providers.
 */

	/**
	 * list of correspondence template entries based on Letter Type with automatic filtering for User rights and Auth workflow
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceInfoHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.correspondenceinfo.CorrespondenceInfoService.browse()
	 *
	 * correspondenceInfoId: number
	 * name: string
	 * correspondenceCode: string
	 * wellKnownId: string
	 * letterType: string
	 * subType: string
	 * effectiveDateTime: Date
	 * active: boolean
	 * lineOfBusiness: string
	 * isSelectable: boolean
	 * programBits: number
	 * isQuickLetter: boolean
	 * templateType: string
	 * visitStatus: string
	 * isWorkList: boolean
	 * returns: CorrespondenceInfoResponse
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';

	export const browse = ({correspondenceInfoId, name, correspondenceCode, wellKnownId, letterType, subType, effectiveDateTime, active, lineOfBusiness, isSelectable, programBits, isQuickLetter, templateType, visitStatus, isWorkList, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/list';

		const params = {
			correspondenceInfoId,
			name,
			correspondenceCode,
			wellKnownId,
			letterType,
			subType,
			effectiveDateTime,
			active,
			lineOfBusiness,
			isSelectable,
			programBits,
			isQuickLetter,
			templateType,
			visitStatus,
			isWorkList
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-infos-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';

	/**
	 * create or update the correspondence info template data from payload
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceInfoHandler.doEnterCorrespTemplate()
	 * @see com.thrasys.xnet.erp.resthandler.correspondenceinfo.CorrespondenceInfoService.enterCorrespTemplate()
	 *
	 * correspondenceInfoDetail: CorrespondenceInfoDetail
	 * returns: XgCorrespondenceInfoDetail
	 */
	import enterCorrespTemplateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail.json';
	import enterCorrespTemplateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail.json';

	export const enterCorrespTemplate = (correspondenceInfoDetail, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/enterCorrespTemplate';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = correspondenceInfoDetail;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-infos-svc',
			action: 'enterCorrespTemplate',
			requestSchema: enterCorrespTemplateRequestSchema,
			responseSchema: enterCorrespTemplateResponseSchema
		});
	};

	enterCorrespTemplate.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail.json';
	enterCorrespTemplate.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail.json';

	/**
	 * get the template tokens for WORD FileID using XG data 
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceInfoHandler.doGetCorrespTemplate()
	 * @see com.thrasys.xnet.erp.resthandler.correspondenceinfo.CorrespondenceInfoService.getCorrespTemplate()
	 *
	 * fileId: string
	 * correspondenceInfoId: number
	 * wellKnownId: string
	 * returns: XgCorrespondenceInfoDetail
	 */
	import getCorrespTemplateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail.json';

	export const getCorrespTemplate = ({fileId, correspondenceInfoId, wellKnownId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/getCorrespTemplate';

		const params = {
			fileId,
			correspondenceInfoId,
			wellKnownId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-infos-svc',
			action: 'getCorrespTemplate',
			responseSchema: getCorrespTemplateResponseSchema
		});
	};

	getCorrespTemplate.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail.json';

	/**
	 * retrieve custom language content for a CorrespondenceInfoID
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceInfoHandler.doGetCustomLanguage()
	 * @see com.thrasys.xnet.erp.resthandler.correspondenceinfo.CorrespondenceInfoService.getCustomLanguage()
	 *
	 * correspondenceInfoId: number
	 * isLookupIncludeText: boolean
	 * customLanguageId: number
	 * searchText: string
	 * ipid: string
	 * ivid: string
	 * maxSizeCustomLanguage: number
	 * isTranslationReq: boolean
	 * returns: CorrespondenceInfoResponse
	 */
	import getCustomLanguageResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';

	export const getCustomLanguage = ({correspondenceInfoId, isLookupIncludeText, customLanguageId, searchText, ipid, ivid, maxSizeCustomLanguage, isTranslationReq, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/content';

		const params = {
			correspondenceInfoId,
			isLookupIncludeText,
			customLanguageId,
			searchText,
			ipid,
			ivid,
			maxSizeCustomLanguage,
			isTranslationReq
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-infos-svc',
			action: 'getCustomLanguage',
			responseSchema: getCustomLanguageResponseSchema
		});
	};

	getCustomLanguage.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';

	/**
	 * get correspondence reference data  type tree
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceInfoHandler.doGetTypeTree()
	 * @see com.thrasys.xnet.erp.resthandler.correspondenceinfo.CorrespondenceInfoService.getTypeTree()
	 *
	 * returns: XeCorrespondenceTypeTree
	 */
	import getTypeTreeResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.refdata.XeCorrespondenceTypeTree.json';

	export const getTypeTree = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/typeTree';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-infos-svc',
			action: 'getTypeTree',
			responseSchema: getTypeTreeResponseSchema
		});
	};

	getTypeTree.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.refdata.XeCorrespondenceTypeTree.json';

	/**
	 * list of quick letter correspondence info entries, with custom Language entry
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceInfoHandler.doQueryQuickLetters()
	 * @see com.thrasys.xnet.erp.resthandler.correspondenceinfo.CorrespondenceInfoService.queryQuickLetters()
	 *
	 * letterType: string
	 * subType: string
	 * lineOfBusiness: string
	 * effectiveDateTime: Date
	 * programBits: number
	 * returns: CorrespondenceInfoResponse
	 */
	import queryQuickLettersResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';

	export const queryQuickLetters = ({letterType, subType, lineOfBusiness, effectiveDateTime, programBits, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/queryQuickLetters';

		const params = {
			letterType,
			subType,
			lineOfBusiness,
			effectiveDateTime,
			programBits
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-infos-svc',
			action: 'queryQuickLetters',
			responseSchema: queryQuickLettersResponseSchema
		});
	};

	queryQuickLetters.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';

	/**
	 * list of correspondence info entries 
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceInfoHandler.doQueryTemplate()
	 * @see com.thrasys.xnet.erp.resthandler.correspondenceinfo.CorrespondenceInfoService.queryTemplate()
	 *
	 * name: string
	 * letterType: string
	 * isQuickLetter: boolean
	 * templateType: string
	 * active: boolean
	 * isSelectable: boolean
	 * effectiveDateTime: Date
	 * returns: CorrespondenceInfoResponse
	 */
	import queryTemplateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';

	export const queryTemplate = ({name, letterType, isQuickLetter, templateType, active, isSelectable, effectiveDateTime, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/queryTemplate';

		const params = {
			name,
			letterType,
			isQuickLetter,
			templateType,
			active,
			isSelectable,
			effectiveDateTime
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-infos-svc',
			action: 'queryTemplate',
			responseSchema: queryTemplateResponseSchema
		});
	};

	queryTemplate.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse.json';

	/**
	 * update correspondence info data from payload
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceInfoHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.correspondenceinfo.CorrespondenceInfoService.update()
	 *
	 * correspondenceInfoRequest: CorrespondenceInfoRequest
	 * returns: XgCorrespondenceInfoResponse
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoRequest.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoResponse.json';

	export const update = (correspondenceInfoRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/update';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = correspondenceInfoRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-infos-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoRequest.json';
	update.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoResponse.json';
