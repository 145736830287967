/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Maintenance and access to clindoc data backed by asset XeAssessment (XE_CDOC).
 */

	/**
	 * Query clindoc specifications
	 * @see com.thrasys.xnet.erp.clinical.handler.ClindocHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.clindoc.ClindocService.browse()
	 *
	 * searchTerm: string
	 * assessmentTypeId: string
	 * assessmentSubTypeId: string
	 * isScanDoc: boolean
	 * associationType: string
	 * programBits: number
	 * active: boolean
	 * internalCode: string
	 * isAssessmentAndSet: boolean
	 * returns: XeAssessmentBase
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.assessment.XeAssessmentBase.json';

	export const browse = ({searchTerm, assessmentTypeId, assessmentSubTypeId, isScanDoc, associationType, programBits, active, internalCode, isAssessmentAndSet, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/clinDoc/list';

		const params = {
			searchTerm,
			assessmentTypeId,
			assessmentSubTypeId,
			isScanDoc,
			associationType,
			programBits,
			active,
			internalCode,
			isAssessmentAndSet
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-clindocs-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.assessment.XeAssessmentBase.json';

	/**
	 * Query an ASSESSMENT_SET to get individual assessments
	 * @see com.thrasys.xnet.erp.clinical.handler.ClindocHandler.doGetGroupList()
	 * @see com.thrasys.xnet.erp.resthandler.clindoc.ClindocService.getGroupList()
	 *
	 * assessmentId: number
	 * ipid: number
	 * ivid: number
	 * returns: AssessmentGroupResponse
	 */
	import getGroupListResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse.json';

	export const getGroupList = ({assessmentId, ipid, ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/clinDoc/groupList';

		const params = {
			assessmentId,
			ipid,
			ivid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-clindocs-svc',
			action: 'getGroupList',
			responseSchema: getGroupListResponseSchema
		});
	};

	getGroupList.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse.json';
