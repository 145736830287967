/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Manage and maintain clearing houses for submission on claim batches
 */

	/**
	 * Add new clearing house
	 * @see com.thrasys.xnet.erp.billing.handler.ClearingHouseHandler.doAdd()
	 * @see com.thrasys.xnet.erp.resthandler.clearinghouse.ClearingHouseService.add()
	 *
	 * assetXeClearingHouse: AssetXeClearingHouse
	 * returns: AssetXeClearingHouse
	 */
	import addRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
	import addResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';

	export const add = (assetXeClearingHouse, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/clearingHouse';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeClearingHouse;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-clearing-houses-svc',
			action: 'add',
			requestSchema: addRequestSchema,
			responseSchema: addResponseSchema
		});
	};

	add.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
	add.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';

	/**
	 * Search clearing house by name or id
	 * @see com.thrasys.xnet.erp.billing.handler.ClearingHouseHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.clearinghouse.ClearingHouseService.browse()
	 *
	 * clearingHouseId: string
	 * healthPlanPayerId: string
	 * searchText: string
	 * active: boolean
	 * returns: ClearingHouseFull
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.clearinghouse.ClearingHouseFull.json';

	export const browse = ({clearingHouseId, healthPlanPayerId, searchText, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/clearingHouse/browse';

		const params = {
			clearingHouseId,
			healthPlanPayerId,
			searchText,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-clearing-houses-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.clearinghouse.ClearingHouseFull.json';

	/**
	 * Return detail for ClearinghouseID
	 * @see com.thrasys.xnet.erp.billing.handler.ClearingHouseHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.clearinghouse.ClearingHouseService.getDetails()
	 *
	 * clearingHouseId: string
	 * returns: ClearingHouseFull
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.clearinghouse.ClearingHouseFull.json';

	export const getDetails = ({clearingHouseId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/clearingHouse/details/' + clearingHouseId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-clearing-houses-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.clearinghouse.ClearingHouseFull.json';

	/**
	 * Mark Clearing House as in error - deactivate - globally
	 * @see com.thrasys.xnet.erp.billing.handler.ClearingHouseHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.clearinghouse.ClearingHouseService.markInError()
	 *
	 * assetXeClearingHouse: AssetXeClearingHouse
	 * returns: AssetXeClearingHouse
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';

	export const markInError = (assetXeClearingHouse, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/clearingHouse/markInError';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeClearingHouse;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-clearing-houses-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';

	/**
	 * update clearing house data
	 * @see com.thrasys.xnet.erp.billing.handler.ClearingHouseHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.clearinghouse.ClearingHouseService.update()
	 *
	 * clearingHouseId: string
	 * assetXeClearingHouse: AssetXeClearingHouse
	 * returns: AssetXeClearingHouse
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';

	export const update = (assetXeClearingHouse, {clearingHouseId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/clearingHouse/' + clearingHouseId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeClearingHouse;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-clearing-houses-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
	update.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeClearingHouse.json';
