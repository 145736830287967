/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Manage and maintain health plan records based on asset Xg/Xa/XeHealhtPlan
 */

	/**
	 * Return a list health plans by name
	 * @see com.thrasys.xnet.erp.billing.handler.HealthPlanHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.healthplan.HealthPlanService.browse()
	 *
	 * searchText: string
	 * healthPlanId: string
	 * active: boolean
	 * returns: AssetXeHealthPlan
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeHealthPlan.json';

	export const browse = ({searchText, healthPlanId, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/config/healthPlan/list';

		const params = {
			searchText,
			healthPlanId,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-health-plans-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeHealthPlan.json';
