/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Create an encounter form and all its components and push through submission process.
 */

	/**
	 * Approve an existing encounter form
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doApprove()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.approve()
	 *
	 * encFormId: number
	 * returns: EncFormTransactionResponse
	 */
	import approveResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const approve = ({encFormId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/approve/' + encFormId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'approve',
			responseSchema: approveResponseSchema
		});
	};

	approve.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	/**
	 * Search encounter forms matching criteria set in query params. At least one parameter must be provided, although every parameter is optional.
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.browse()
	 *
	 * xeEncFormBrowseRequest: XeEncFormBrowseRequest
	 * returns: XeEncFormFull
	 */
	import browseRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormBrowseRequest.json';
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormFull.json';

	export const browse = (xeEncFormBrowseRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/search';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeEncFormBrowseRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-enc-forms-svc',
			action: 'browse',
			requestSchema: browseRequestSchema,
			responseSchema: browseResponseSchema
		});
	};

	browse.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormBrowseRequest.json';
	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormFull.json';

	/**
	 * Set the status of an invoice statement from Created or Issued to Cancelled as the indication that the invoice has been cancelled/void.
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doCancelInvoiceStatement()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.cancelInvoiceStatement()
	 *
	 * statementId: number
	 * comments: string
	 * returns: AssetXeStatement
	 */
	import cancelInvoiceStatementResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	export const cancelInvoiceStatement = ({statementId, comments, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/invoice/statement/cancel';

		const params = {
			statementId,
			comments
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'cancelInvoiceStatement',
			responseSchema: cancelInvoiceStatementResponseSchema
		});
	};

	cancelInvoiceStatement.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	/**
	 * Set the status of an invoice statement from Issued to Closed as the indication that the invoice has been paid.
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doCloseInvoiceStatement()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.closeInvoiceStatement()
	 *
	 * statementId: number
	 * comments: string
	 * returns: AssetXeStatement
	 */
	import closeInvoiceStatementResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	export const closeInvoiceStatement = ({statementId, comments, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/invoice/statement/close';

		const params = {
			statementId,
			comments
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'closeInvoiceStatement',
			responseSchema: closeInvoiceStatementResponseSchema
		});
	};

	closeInvoiceStatement.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	/**
	 * Get the Copy of the EncForm data for the given EncFormID
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doCopyEncounter()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.copyEncounter()
	 *
	 * encFormEmptyRequest: EncFormEmptyRequest
	 * returns: XeEncFormWithPxDx
	 */
	import copyEncounterRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormEmptyRequest.json';
	import copyEncounterResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';

	export const copyEncounter = (encFormEmptyRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/copyEncounter';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = encFormEmptyRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-enc-forms-svc',
			action: 'copyEncounter',
			requestSchema: copyEncounterRequestSchema,
			responseSchema: copyEncounterResponseSchema
		});
	};

	copyEncounter.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormEmptyRequest.json';
	copyEncounter.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';

	/**
	 * Get a report of submitted encounter forms in forms of CSV and TXT
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doGenerateCsvAndTab()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.generateCsvAndTab()
	 *
	 * inputFile: string
	 * compress: boolean
	 * startDate: Date
	 * endDate: Date
	 * returns: GenerateReportCsvResponse
	 */
	import generateCsvAndTabResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.GenerateReportCsvResponse.json';

	export const generateCsvAndTab = ({inputFile, compress, startDate, endDate, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/generateCsvAndTab';

		const params = {
			inputFile,
			compress,
			startDate,
			endDate
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'generateCsvAndTab',
			responseSchema: generateCsvAndTabResponseSchema
		});
	};

	generateCsvAndTab.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.GenerateReportCsvResponse.json';

	/**
	 * Get a report of submitted encounter forms in form of XML
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doGenerateReportXml()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.generateReportXml()
	 *
	 * inputFile: string
	 * startDate: Date
	 * endDate: Date
	 * returns: GenerateReportXmlResponse
	 */
	import generateReportXmlResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.response.GenerateReportXmlResponse.json';

	export const generateReportXml = ({inputFile, startDate, endDate, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/generateReportXml';

		const params = {
			inputFile,
			startDate,
			endDate
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'generateReportXml',
			responseSchema: generateReportXmlResponseSchema
		});
	};

	generateReportXml.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.response.GenerateReportXmlResponse.json';

	/**
	 * Return the full details of an encounter form.
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.getDetails()
	 *
	 * encFormId: number
	 * returns: FactXeEncForm
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm.json';

	export const getDetails = ({encFormId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/details/' + encFormId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.FactXeEncForm.json';

	/**
	 * Query invoice statement templates
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doGetInvoiceReport()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.getInvoiceReport()
	 *
	 * returns: AssetXeStatementReport
	 */
	import getInvoiceReportResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeStatementReport.json';

	export const getInvoiceReport = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/invoice/template';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'getInvoiceReport',
			responseSchema: getInvoiceReportResponseSchema
		});
	};

	getInvoiceReport.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeStatementReport.json';

	/**
	 * Query existing invoice statements
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doGetInvoiceStatement()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.getInvoiceStatement()
	 *
	 * dateTimeMin: Date
	 * dateTimeMax: Date
	 * isFileGenerated: boolean
	 * hasReportId: boolean
	 * statusId: string
	 * returns: AssetXeStatement
	 */
	import getInvoiceStatementResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	export const getInvoiceStatement = ({dateTimeMin, dateTimeMax, isFileGenerated, hasReportId, statusId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/invoice/statement';

		const params = {
			dateTimeMin,
			dateTimeMax,
			isFileGenerated,
			hasReportId,
			statusId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'getInvoiceStatement',
			responseSchema: getInvoiceStatementResponseSchema
		});
	};

	getInvoiceStatement.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	/**
	 * Get all pending encounter forms that cannot be submitted
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doGetPendingForms()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.getPendingForms()
	 *
	 * returns: XeEncFormGetPendingResponse
	 */
	import getPendingFormsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse.json';

	export const getPendingForms = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/getPendingForms';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'getPendingForms',
			responseSchema: getPendingFormsResponseSchema
		});
	};

	getPendingForms.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormGetPendingResponse.json';

	/**
	 * Get diagnoses applicable for a new encounter of the patient based on diagnoses of previous encounter(s).
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doGetPreviousDiagnosis()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.getPreviousDiagnosis()
	 *
	 * ipid: number
	 * encounterDate: Date
	 * billingProviderId: number
	 * performProviderId: number
	 * returns: XeEncFormForDx
	 */
	import getPreviousDiagnosisResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx.json';

	export const getPreviousDiagnosis = ({ipid, encounterDate, billingProviderId, performProviderId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/previousDiagnosis';

		const params = {
			ipid,
			encounterDate,
			billingProviderId,
			performProviderId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'getPreviousDiagnosis',
			responseSchema: getPreviousDiagnosisResponseSchema
		});
	};

	getPreviousDiagnosis.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormForDx.json';

	/**
	 * Invoice encounter chanrges with a date range into a billing statement file , or generating the file for an existing invoice statement.
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doInvoiceCharges()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.invoiceCharges()
	 *
	 * encounterDateMin: Date
	 * encounterDateMax: Date
	 * reportId: number
	 * statementId: number
	 * returns: AssetXeStatement
	 */
	import invoiceChargesResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	export const invoiceCharges = ({encounterDateMin, encounterDateMax, reportId, statementId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/invoice/charges';

		const params = {
			encounterDateMin,
			encounterDateMax,
			reportId,
			statementId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'invoiceCharges',
			responseSchema: invoiceChargesResponseSchema
		});
	};

	invoiceCharges.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	/**
	 * Set the status of an invoice statement from Created to Issued as the indication that the invoice has been executed/sent.
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doIssueInvoiceStatement()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.issueInvoiceStatement()
	 *
	 * statementId: number
	 * returns: AssetXeStatement
	 */
	import issueInvoiceStatementResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	export const issueInvoiceStatement = ({statementId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/invoice/statement/issue';

		const params = {
			statementId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'issueInvoiceStatement',
			responseSchema: issueInvoiceStatementResponseSchema
		});
	};

	issueInvoiceStatement.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeStatement.json';

	/**
	 * Mark an existing encounter form as in error
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doMarkEncounterInError()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.markEncounterInError()
	 *
	 * encFormId: number
	 * returns: EncFormTransactionResponse
	 */
	import markEncounterInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const markEncounterInError = ({encFormId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/markEncounterInError/' + encFormId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'markEncounterInError',
			responseSchema: markEncounterInErrorResponseSchema
		});
	};

	markEncounterInError.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	/**
	 * Mark an existing encounter form as is sent outbound
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doMarkIsSent()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.markIsSent()
	 *
	 * encFormId: number
	 * outboundQueuedTime: Date
	 * returns: EncFormTransactionResponse
	 */
	import markIsSentResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const markIsSent = ({encFormId, outboundQueuedTime, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/markIsSent/' + encFormId;

		const params = {
			outboundQueuedTime
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'markIsSent',
			responseSchema: markIsSentResponseSchema
		});
	};

	markIsSent.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	/**
	 * Insert a new encounter form (XeEncForm)
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doNewEncounter()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.newEncounter()
	 *
	 * xeEncFormWithPxDx: XeEncFormWithPxDx
	 * returns: XeEncFormWithPxDx
	 */
	import newEncounterRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';
	import newEncounterResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';

	export const newEncounter = (xeEncFormWithPxDx, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/newEncounter';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeEncFormWithPxDx;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-enc-forms-svc',
			action: 'newEncounter',
			requestSchema: newEncounterRequestSchema,
			responseSchema: newEncounterResponseSchema
		});
	};

	newEncounter.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';
	newEncounter.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';

	/**
	 * Override the validating process and force the existing encounter form be marked as valid
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doOverrideValidate()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.overrideValidate()
	 *
	 * encFormId: number
	 * returns: EncFormTransactionResponse
	 */
	import overrideValidateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const overrideValidate = ({encFormId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/overrideValidate/' + encFormId + '/validate';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'overrideValidate',
			responseSchema: overrideValidateResponseSchema
		});
	};

	overrideValidate.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	/**
	 * Revalidate all pending encounter forms now
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doRevalidate()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.revalidate()
	 *
	 * returns: EncFormTransactionResponse
	 */
	import revalidateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const revalidate = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/revalidate';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'revalidate',
			responseSchema: revalidateResponseSchema
		});
	};

	revalidate.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	/**
	 * Revalidate all pending encounter forms in a background process
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doRevalidateInBackground()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.revalidateInBackground()
	 *
	 * returns: EncFormTransactionResponse
	 */
	import revalidateInBackgroundResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const revalidateInBackground = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/revalidateInBackground';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'revalidateInBackground',
			responseSchema: revalidateInBackgroundResponseSchema
		});
	};

	revalidateInBackground.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	/**
	 * Try to submit an existing encounter form resulting with a status dependent on validating result
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doSubmit()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.submit()
	 *
	 * encFormId: number
	 * returns: EncFormTransactionResponse
	 */
	import submitResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const submit = ({encFormId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/submit/' + encFormId + '/submit';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'submit',
			responseSchema: submitResponseSchema
		});
	};

	submit.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	/**
	 * Submit all pending encounter forms against a specified patient
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doSubmitPendingEncounterForms()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.submitPendingEncounterForms()
	 *
	 * ipid: number
	 * enterpriseId: string
	 * returns: EncFormTransactionResponse
	 */
	import submitPendingEncounterFormsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const submitPendingEncounterForms = ({ipid, enterpriseId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/submitPendingEncounterForms/' + ipid;

		const params = {
			enterpriseId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-enc-forms-svc',
			action: 'submitPendingEncounterForms',
			responseSchema: submitPendingEncounterFormsResponseSchema
		});
	};

	submitPendingEncounterForms.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	/**
	 * Update an existing encounter form (XeEncForm)
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.update()
	 *
	 * encFormId: number
	 * xeEncFormWithPxDx: XeEncFormWithPxDx
	 * returns: XeEncFormWithPxDx
	 */
	import updateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';
	import updateResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';

	export const update = (xeEncFormWithPxDx, {encFormId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/update/' + encFormId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeEncFormWithPxDx;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-enc-forms-svc',
			action: 'update',
			requestSchema: updateRequestSchema,
			responseSchema: updateResponseSchema
		});
	};

	update.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';
	update.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.XeEncFormWithPxDx.json';

	/**
	 * Update outbound reported timestamp of a list of existing encounter forms with Id values listed.  The outbound sending timestamp is required.
	 * @see com.thrasys.xnet.erp.billing.handler.EncFormHandler.doUpdateReport()
	 * @see com.thrasys.xnet.erp.resthandler.encform.EncFormService.updateReport()
	 *
	 * encFormReportTimeRequest: EncFormReportTimeRequest
	 * returns: EncFormTransactionResponse
	 */
	import updateReportRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormReportTimeRequest.json';
	import updateReportResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';

	export const updateReport = (encFormReportTimeRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/encForm/updateReport';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = encFormReportTimeRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-enc-forms-svc',
			action: 'updateReport',
			requestSchema: updateReportRequestSchema,
			responseSchema: updateReportResponseSchema
		});
	};

	updateReport.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormReportTimeRequest.json';
	updateReport.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.encform.EncFormTransactionResponse.json';
