/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Add, delete and get files from XNet file storage
 */

	/**
	 * Add or update a file into XNet file storage
	 * @see com.thrasys.xnet.config.FileStorageHandler.doAddFile()
	 * @see com.thrasys.xnet.resthandler.filestorage.FileStorageService.addFile()
	 *
	 * file: File
	 * returns: XeFileResponse
	 */
	import addFileResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeFileResponse.json';

	export const addFile = (file, {xxsid}, makeRequest) => {
		var fd = new FormData();
		fd.append('name', file.name);
		fd.append('data', file);

		const theRequestUrl = '/rest/files/addFile';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = fd;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-file-storages-svc',
			action: 'addFile',
			responseSchema: addFileResponseSchema
		});
	};

	addFile.responseSchema = 'com.thrasys.xnet.xmlobjects.XeFileResponse.json';

	/**
	 * Adds files into XNet file storage
	 * @see com.thrasys.xnet.config.FileStorageHandler.doAddFiles()
	 * @see com.thrasys.xnet.resthandler.filestorage.FileStorageService.addFiles()
	 *
	 * file: File []
	 * returns: XeMultiFileResponse
	 */
	import addFilesResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeMultiFileResponse.json';

	export const addFiles = (file, {xxsid}, makeRequest) => {
		var fd = new FormData();
		for (tmpfl in file) {
			fd.append('name', tmpfl.name);
			fd.append('data', tmpfl);
		}

		const theRequestUrl = '/rest/files/addFiles';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = fd;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-file-storages-svc',
			action: 'addFiles',
			responseSchema: addFilesResponseSchema
		});
	};

	addFiles.responseSchema = 'com.thrasys.xnet.xmlobjects.XeMultiFileResponse.json';

	/**
	 * Delete file from XNet file storage
	 * @see com.thrasys.xnet.config.FileStorageHandler.doDelete()
	 * @see com.thrasys.xnet.resthandler.filestorage.FileStorageService.delete()
	 *
	 * fileId: number
	 * returns: XeFileResponse
	 */
	import doDeleteResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeFileResponse.json';

	export const doDelete = ({fileId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/files/deleteFile/' + fileId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-file-storages-svc',
			action: 'doDelete',
			responseSchema: doDeleteResponseSchema
		});
	};

	doDelete.responseSchema = 'com.thrasys.xnet.xmlobjects.XeFileResponse.json';

	/**
	 * Get file from XNet file storage
	 * @see com.thrasys.xnet.config.FileStorageHandler.doGetFile()
	 * @see com.thrasys.xnet.resthandler.filestorage.FileStorageService.getFile()
	 *
	 * fileId: number
	 * returns: Response
	 */

	export const getFile = ({fileId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/files/getFile/' + fileId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'blob',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-file-storages-svc',
			action: 'getFile'
		});
	};


	/**
	 * Merge requested FileIDs into a single PDF - for ease in printing, SourceFiledIDList supported for backwards compatibility.
	 * @see com.thrasys.xnet.config.FileStorageHandler.doMergePDFFiles()
	 * @see com.thrasys.xnet.resthandler.filestorage.FileStorageService.mergePDFFiles()
	 *
	 * xeFileStorageMergeRequest: XeFileStorageMergeRequest
	 * returns: XeFileStorageMessage
	 */
	import mergePDFFilesRequestSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeFileStorageMergeRequest.json';
	import mergePDFFilesResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeFileStorageMessage.json';

	export const mergePDFFiles = (xeFileStorageMergeRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/files/mergePDFFiles';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeFileStorageMergeRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-file-storages-svc',
			action: 'mergePDFFiles',
			requestSchema: mergePDFFilesRequestSchema,
			responseSchema: mergePDFFilesResponseSchema
		});
	};

	mergePDFFiles.requestSchema = 'com.thrasys.xnet.xmlobjects.XeFileStorageMergeRequest.json';
	mergePDFFiles.responseSchema = 'com.thrasys.xnet.xmlobjects.XeFileStorageMessage.json';
