/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Diagnosis handler (XeDiagnosis)
 */

	/**
	 * Add a diagnosis to a catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doAddCatalogDiagnosis()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.addCatalogDiagnosis()
	 *
	 * diagnosisId: string
	 * catalogId: number
	 * diagnosisCatalogItemMin: DiagnosisCatalogItemMin
	 * returns: DiagnosisCatalogItemMin
	 */
	import addCatalogDiagnosisRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';
	import addCatalogDiagnosisResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';

	export const addCatalogDiagnosis = (diagnosisCatalogItemMin, {diagnosisId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/userCatalog/item/' + diagnosisId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = diagnosisCatalogItemMin;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-diagnosis-svc',
			action: 'addCatalogDiagnosis',
			requestSchema: addCatalogDiagnosisRequestSchema,
			responseSchema: addCatalogDiagnosisResponseSchema
		});
	};

	addCatalogDiagnosis.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';
	addCatalogDiagnosis.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';

	/**
	 * Add or Replace a diagnosis catalog with the given list of DiagnosisCode values
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doAddRefCatalog()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.addRefCatalog()
	 *
	 * addDiagnosisRefCatalogRequest: AddDiagnosisRefCatalogRequest
	 * returns: AssetXeDiagnosisCatalog
	 */
	import addRefCatalogRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.AddDiagnosisRefCatalogRequest.json';
	import addRefCatalogResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeDiagnosisCatalog.json';

	export const addRefCatalog = (addDiagnosisRefCatalogRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/userCatalog';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addDiagnosisRefCatalogRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-diagnosis-svc',
			action: 'addRefCatalog',
			requestSchema: addRefCatalogRequestSchema,
			responseSchema: addRefCatalogResponseSchema
		});
	};

	addRefCatalog.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.AddDiagnosisRefCatalogRequest.json';
	addRefCatalog.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeDiagnosisCatalog.json';

	/**
	 * Get all diagnosis in a catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doGetCatalogDiagnosis()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.getCatalogDiagnosis()
	 *
	 * catalogId: number
	 * ipid: number
	 * returns: DiagnosisCatalogItemFull
	 */
	import getCatalogDiagnosisResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemFull.json';

	export const getCatalogDiagnosis = ({catalogId, ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/userCatalog/items';

		const params = {
			catalogId,
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-diagnosis-svc',
			action: 'getCatalogDiagnosis',
			responseSchema: getCatalogDiagnosisResponseSchema
		});
	};

	getCatalogDiagnosis.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemFull.json';

	/**
	 * Get Diagnosis record by diagnosisId.
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doGetDiagnosis()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.getDiagnosis()
	 *
	 * diagnosisId: string
	 * returns: SearchXeDiagnosis
	 */
	import getDiagnosisResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.SearchXeDiagnosis.json';

	export const getDiagnosis = ({diagnosisId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/code/' + diagnosisId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-diagnosis-svc',
			action: 'getDiagnosis',
			responseSchema: getDiagnosisResponseSchema
		});
	};

	getDiagnosis.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.SearchXeDiagnosis.json';

	/**
	 * Get all diagnosis catalogs configurated for the user.
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doGetUserCatalog()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.getUserCatalog()
	 *
	 * catalogId: number
	 * ipid: number
	 * returns: XeDiagnosisCatalogWithItems
	 */
	import getUserCatalogResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosiscatalog.XeDiagnosisCatalogWithItems.json';

	export const getUserCatalog = ({catalogId, ipid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/userCatalog';

		const params = {
			catalogId,
			ipid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-diagnosis-svc',
			action: 'getUserCatalog',
			responseSchema: getUserCatalogResponseSchema
		});
	};

	getUserCatalog.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosiscatalog.XeDiagnosisCatalogWithItems.json';

	/**
	 * Remove a diagnosis from a catalog.
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doRemoveCatalogDiagnosis()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.removeCatalogDiagnosis()
	 *
	 * diagnosisId: string
	 * catalogId: number
	 * returns: DiagnosisCatalogItemMin
	 */
	import removeCatalogDiagnosisResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';

	export const removeCatalogDiagnosis = ({diagnosisId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/userCatalog/item/' + diagnosisId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'DELETE',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-diagnosis-svc',
			action: 'removeCatalogDiagnosis',
			responseSchema: removeCatalogDiagnosisResponseSchema
		});
	};

	removeCatalogDiagnosis.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';

	/**
	 * Get list of DRG's that match the Query Parameters.
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doSearchDRG()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.searchDRG()
	 *
	 * searchText: string
	 * effectiveDate: Date
	 * active: boolean
	 * returns: SearchXgDRG
	 */
	import searchDRGResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.drg.SearchXgDRG.json';

	export const searchDRG = ({searchText, effectiveDate, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/drg';

		const params = {
			searchText,
			effectiveDate,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-diagnosis-svc',
			action: 'searchDRG',
			responseSchema: searchDRGResponseSchema
		});
	};

	searchDRG.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.drg.SearchXgDRG.json';

	/**
	 * Get list of diagnoses that match the Query Parameters. Returns null if neither SearchTerm nor Code is supplied.
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doSearchDiagnoses()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.searchDiagnoses()
	 *
	 * searchText: string
	 * code: string
	 * codeTypeId: string
	 * active: boolean
	 * catalogId: number
	 * returns: SearchXeDiagnosis
	 */
	import searchDiagnosesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.SearchXeDiagnosis.json';

	export const searchDiagnoses = ({searchText, code, codeTypeId, active, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/code';

		const params = {
			searchText,
			code,
			codeTypeId,
			active,
			catalogId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-diagnosis-svc',
			action: 'searchDiagnoses',
			responseSchema: searchDiagnosesResponseSchema
		});
	};

	searchDiagnoses.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.SearchXeDiagnosis.json';

	/**
	 * Update (the Sequence of) a diagnosis in a catalog.  A sequence number is required.
	 * @see com.thrasys.xnet.erp.clinical.handler.DiagnosisHandler.doUpdateCatalogDiagnosis()
	 * @see com.thrasys.xnet.erp.resthandler.diagnosis.DiagnosisService.updateCatalogDiagnosis()
	 *
	 * diagnosisId: string
	 * catalogId: number
	 * diagnosisCatalogItemMin: DiagnosisCatalogItemMin
	 * returns: DiagnosisCatalogItemMin
	 */
	import updateCatalogDiagnosisRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';
	import updateCatalogDiagnosisResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';

	export const updateCatalogDiagnosis = (diagnosisCatalogItemMin, {diagnosisId, catalogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/clinicalRefData/diagnosis/userCatalog/item/' + diagnosisId + '/' + catalogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = diagnosisCatalogItemMin;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-diagnosis-svc',
			action: 'updateCatalogDiagnosis',
			requestSchema: updateCatalogDiagnosisRequestSchema,
			responseSchema: updateCatalogDiagnosisResponseSchema
		});
	};

	updateCatalogDiagnosis.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';
	updateCatalogDiagnosis.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.diagnosis.DiagnosisCatalogItemMin.json';
