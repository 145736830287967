/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Access to dashboard report results
 */

	/**
	 * Create Dashboard Views marked ViewExists as false.
	 * @see com.thrasys.xnet.erp.analytics.handler.DashboardHandler.doCreateDroppedView()
	 * @see com.thrasys.xnet.erp.resthandler.dashboard.DashboardService.createDroppedView()
	 *
	 * dashboardViewRequest: DashboardViewRequest
	 * returns: DashboardViewRequest
	 */
	import createDroppedViewRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardViewRequest.json';
	import createDroppedViewResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardViewRequest.json';

	export const createDroppedView = (dashboardViewRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/analytics/dashboard/createDroppedView';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = dashboardViewRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-dashboards-svc',
			action: 'createDroppedView',
			requestSchema: createDroppedViewRequestSchema,
			responseSchema: createDroppedViewResponseSchema
		});
	};

	createDroppedView.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardViewRequest.json';
	createDroppedView.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardViewRequest.json';

	/**
	 * Drop Dashboard Views by Asset Name.
	 * @see com.thrasys.xnet.erp.analytics.handler.DashboardHandler.doDropView()
	 * @see com.thrasys.xnet.erp.resthandler.dashboard.DashboardService.dropView()
	 *
	 * dashboardViewRequest: DashboardViewRequest
	 * returns: DashboardViewRequest
	 */
	import dropViewRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardViewRequest.json';
	import dropViewResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardViewRequest.json';

	export const dropView = (dashboardViewRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/analytics/dashboard/dropView';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = dashboardViewRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-dashboards-svc',
			action: 'dropView',
			requestSchema: dropViewRequestSchema,
			responseSchema: dropViewResponseSchema
		});
	};

	dropView.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardViewRequest.json';
	dropView.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardViewRequest.json';

	/**
	 * Export a file based on a dashboard definition
	 * @see com.thrasys.xnet.erp.analytics.handler.DashboardHandler.doExportDataAsCSV()
	 * @see com.thrasys.xnet.erp.resthandler.dashboard.DashboardService.exportDataAsCSV()
	 *
	 * dashboardDetail: DashboardDetail
	 * returns: QueryScanDocResponse
	 */
	import exportDataAsCSVRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';
	import exportDataAsCSVResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse.json';

	export const exportDataAsCSV = (dashboardDetail, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/analytics/dashboard/exportcsv';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = dashboardDetail;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-dashboards-svc',
			action: 'exportDataAsCSV',
			requestSchema: exportDataAsCSVRequestSchema,
			responseSchema: exportDataAsCSVResponseSchema
		});
	};

	exportDataAsCSV.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';
	exportDataAsCSV.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse.json';

	/**
	 * Get the details for a specific dashboard.
	 * @see com.thrasys.xnet.erp.analytics.handler.DashboardHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.dashboard.DashboardService.getDetails()
	 *
	 * dashboardId: string
	 * includeQueryData: boolean
	 * active: boolean
	 * returns: DashboardDetail
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';

	export const getDetails = ({dashboardId, includeQueryData, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/analytics/dashboard/details';

		const params = {
			dashboardId,
			includeQueryData,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-dashboards-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';

	/**
	 * count information for the dashboards in GroupID
	 * @see com.thrasys.xnet.erp.analytics.handler.DashboardHandler.doQueryCount()
	 * @see com.thrasys.xnet.erp.resthandler.dashboard.DashboardService.queryCount()
	 *
	 * dashboardId: string
	 * groupId: string
	 * returns: DashboardStructuredResponse
	 */
	import queryCountResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse.json';

	export const queryCount = ({dashboardId, groupId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/analytics/dashboard/listCount';

		const params = {
			dashboardId,
			groupId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-dashboards-svc',
			action: 'queryCount',
			responseSchema: queryCountResponseSchema
		});
	};

	queryCount.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse.json';

	/**
	 * Generate a file based on a dashboard definition
	 * @see com.thrasys.xnet.erp.analytics.handler.DashboardHandler.doQueryScanDoc()
	 * @see com.thrasys.xnet.erp.resthandler.dashboard.DashboardService.queryScanDoc()
	 *
	 * dashboardDetail: DashboardDetail
	 * returns: QueryScanDocResponse
	 */
	import queryScanDocRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';
	import queryScanDocResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse.json';

	export const queryScanDoc = (dashboardDetail, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/analytics/dashboard/file';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = dashboardDetail;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-dashboards-svc',
			action: 'queryScanDoc',
			requestSchema: queryScanDocRequestSchema,
			responseSchema: queryScanDocResponseSchema
		});
	};

	queryScanDoc.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';
	queryScanDoc.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.scandoc.QueryScanDocResponse.json';

	/**
	 * summary information for  given a DashboardID or GroupID
	 * @see com.thrasys.xnet.erp.analytics.handler.DashboardHandler.doQuerySummary()
	 * @see com.thrasys.xnet.erp.resthandler.dashboard.DashboardService.querySummary()
	 *
	 * dashboardDetail: DashboardDetail
	 * returns: DashboardStructuredResponse
	 */
	import querySummaryRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';
	import querySummaryResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse.json';

	export const querySummary = (dashboardDetail, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/analytics/dashboard/list';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = dashboardDetail;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-dashboards-svc',
			action: 'querySummary',
			requestSchema: querySummaryRequestSchema,
			responseSchema: querySummaryResponseSchema
		});
	};

	querySummary.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';
	querySummary.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse.json';
