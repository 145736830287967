/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Define/Record/Generate correspondence about a patient to themselves and their providers.
 */

	/**
	 * Add a recipient to an already processed letter. Create a pdf for recipient.
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doAddRecipientsToFinal()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.addRecipientsToFinal()
	 *
	 * visitCorrespondenceProcess: VisitCorrespondenceProcess
	 * returns: VisitCorrespondenceProcess
	 */
	import addRecipientsToFinalRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess.json';
	import addRecipientsToFinalResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess.json';

	export const addRecipientsToFinal = (visitCorrespondenceProcess, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/patient/addRecipientsToFinal';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceProcess;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'addRecipientsToFinal',
			requestSchema: addRecipientsToFinalRequestSchema,
			responseSchema: addRecipientsToFinalResponseSchema
		});
	};

	addRecipientsToFinal.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess.json';
	addRecipientsToFinal.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess.json';

	/**
	 * examine current state of correspondence and create a word translation file for every recipient/ or specified recipient. This runs very slowly. Should not ever be called by the UI.
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doApplyTranslationRules()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.applyTranslationRules()
	 *
	 * visitCorrespondenceRequest: VisitCorrespondenceRequest
	 * returns: VisitCorrespondenceProcess
	 */
	import applyTranslationRulesRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
	import applyTranslationRulesResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess.json';

	export const applyTranslationRules = (visitCorrespondenceRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/patient/translationRule';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'applyTranslationRules',
			requestSchema: applyTranslationRulesRequestSchema,
			responseSchema: applyTranslationRulesResponseSchema
		});
	};

	applyTranslationRules.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
	applyTranslationRules.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess.json';

	/**
	 * get list of visit correspondences for a patient. Recipient letters appear as child objects
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.browse()
	 *
	 * visitCorrespondenceId: number
	 * ipid: number
	 * ivid: number
	 * programId: string
	 * letterStatus: string
	 * letterType: string
	 * dateTimeMin: Date
	 * dateTimeMax: Date
	 * active: boolean
	 * isInError: boolean
	 * returns: FactXeVisitCorrespondence
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence.json';

	export const browse = ({visitCorrespondenceId, ipid, ivid, programId, letterStatus, letterType, dateTimeMin, dateTimeMax, active, isInError, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/list';

		const params = {
			visitCorrespondenceId,
			ipid,
			ivid,
			programId,
			letterStatus,
			letterType,
			dateTimeMin,
			dateTimeMax,
			active,
			isInError
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondences-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.FactXeVisitCorrespondence.json';

	/**
	 * Create a clone of the latest not-editable Correspondence found by the parameters in the message (VisitCorrespondenceID or IVID +LetterType+SubType
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doCloneCorrespondence()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.cloneCorrespondence()
	 *
	 * visitCorrespondenceRequest: VisitCorrespondenceRequest
	 * returns: VisitCorrespondenceDetail
	 */
	import cloneCorrespondenceRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
	import cloneCorrespondenceResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail.json';

	export const cloneCorrespondence = (visitCorrespondenceRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/cloneCorrespondence';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'cloneCorrespondence',
			requestSchema: cloneCorrespondenceRequestSchema,
			responseSchema: cloneCorrespondenceResponseSchema
		});
	};

	cloneCorrespondence.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
	cloneCorrespondence.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail.json';

	/**
	 * Creates new visit correspondence which can be generated automatically, since no user input is required.
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doGenerateQuickLetter()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.generateQuickLetter()
	 *
	 * visitCorrespondenceCreateSimpleRequest: VisitCorrespondenceCreateSimpleRequest
	 * returns: VisitCorrespondenceProcessFinalRequest
	 */
	import generateQuickLetterRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest.json';
	import generateQuickLetterResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalRequest.json';

	export const generateQuickLetter = (visitCorrespondenceCreateSimpleRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/patient/quickLetter';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceCreateSimpleRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'generateQuickLetter',
			requestSchema: generateQuickLetterRequestSchema,
			responseSchema: generateQuickLetterResponseSchema
		});
	};

	generateQuickLetter.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest.json';
	generateQuickLetter.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalRequest.json';

	/**
	 * Generate PDF for specified Recipients
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doGenerateRecipientFile()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.generateRecipientFile()
	 *
	 * visitCorrespondenceRequest: VisitCorrespondenceRequest
	 * returns: VisitCorrespondenceRequest
	 */
	import generateRecipientFileRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
	import generateRecipientFileResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';

	export const generateRecipientFile = (visitCorrespondenceRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/patient/generateRecipientFile';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'generateRecipientFile',
			requestSchema: generateRecipientFileRequestSchema,
			responseSchema: generateRecipientFileResponseSchema
		});
	};

	generateRecipientFile.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
	generateRecipientFile.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';

	/**
	 * get the default spec for the specific correspondence info. Return is same as DetailResponse except XeCustomLanguage is an array. Can also be used by rules to create and save a new letter as Draft or Review.
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doGetDefaultSpec()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.getDefaultSpec()
	 *
	 * ipid: number
	 * ivid: number
	 * correspondenceInfoId: number
	 * customLanguageId: number
	 * wellKnownId: string
	 * correspondenceCode: string
	 * isLookupIncludeText: boolean
	 * isIncludeMailCoverSheet: boolean
	 * isIncludeFaxCoverSheet: boolean
	 * faxCoverWellKnownId: string
	 * mailCoverWellKnownId: string
	 * isSaveAsDraft: boolean
	 * isSaveAsReview: boolean
	 * isAuthorSigner: boolean
	 * maxSizeCustomLanguage: number
	 * returns: VisitCorrespondenceDefSpec
	 */
	import getDefaultSpecResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec.json';

	export const getDefaultSpec = ({ipid, ivid, correspondenceInfoId, customLanguageId, wellKnownId, correspondenceCode, isLookupIncludeText, isIncludeMailCoverSheet, isIncludeFaxCoverSheet, faxCoverWellKnownId, mailCoverWellKnownId, isSaveAsDraft, isSaveAsReview, isAuthorSigner, maxSizeCustomLanguage, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/defaultSpec';

		const params = {
			ipid,
			ivid,
			correspondenceInfoId,
			customLanguageId,
			wellKnownId,
			correspondenceCode,
			isLookupIncludeText,
			isIncludeMailCoverSheet,
			isIncludeFaxCoverSheet,
			faxCoverWellKnownId,
			mailCoverWellKnownId,
			isSaveAsDraft,
			isSaveAsReview,
			isAuthorSigner,
			maxSizeCustomLanguage
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondences-svc',
			action: 'getDefaultSpec',
			responseSchema: getDefaultSpecResponseSchema
		});
	};

	getDefaultSpec.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDefSpec.json';

	/**
	 * get visit correspondence detail for the specific VisitCorrespondenceID or matching Workflow Creation
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.getDetails()
	 *
	 * visitCorrespondenceId: number
	 * ivid: number
	 * letterStatus: string
	 * letterType: string
	 * subType: string
	 * wellKnownId: string
	 * active: boolean
	 * isInError: boolean
	 * returns: VisitCorrespondenceDetail
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail.json';

	export const getDetails = ({visitCorrespondenceId, ivid, letterStatus, letterType, subType, wellKnownId, active, isInError, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/details';

		const params = {
			visitCorrespondenceId,
			ivid,
			letterStatus,
			letterType,
			subType,
			wellKnownId,
			active,
			isInError
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondences-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail.json';

	/**
	 * create a pdf that is a draft version of the correspondence-Recipient of draft is included in the message.
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doGetDraft()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.getDraft()
	 *
	 * visitCorrespondenceId: number
	 * visitCorrespRecipId: string
	 * returns: VisitCorrespondenceProcess
	 */
	import getDraftResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess.json';

	export const getDraft = ({visitCorrespondenceId, visitCorrespRecipId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/patient/draftFile';

		const params = {
			visitCorrespondenceId,
			visitCorrespRecipId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondences-svc',
			action: 'getDraft',
			responseSchema: getDraftResponseSchema
		});
	};

	getDraft.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcess.json';

	/**
	 * list of recipients
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doGetRecipients()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.getRecipients()
	 *
	 * ipid: number
	 * ivid: number
	 * letterType: string
	 * correspondenceCode: string
	 * correspondenceInfoId: number
	 * returns: VisitCorrespondenceRecipResponse
	 */
	import getRecipientsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipResponse.json';

	export const getRecipients = ({ipid, ivid, letterType, correspondenceCode, correspondenceInfoId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipients';

		const params = {
			ipid,
			ivid,
			letterType,
			correspondenceCode,
			correspondenceInfoId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondences-svc',
			action: 'getRecipients',
			responseSchema: getRecipientsResponseSchema
		});
	};

	getRecipients.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipResponse.json';

	/**
	 * update the IVID of  VisitCorrespondenceID to the IVID in the message
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doLink()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.link()
	 *
	 * assetXeVisitCorrespondence: AssetXeVisitCorrespondence
	 * returns: AssetXeVisitCorrespondence
	 */
	import linkRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespondence.json';
	import linkResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespondence.json';

	export const link = (assetXeVisitCorrespondence, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/relink';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeVisitCorrespondence;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'link',
			requestSchema: linkRequestSchema,
			responseSchema: linkResponseSchema
		});
	};

	link.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespondence.json';
	link.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespondence.json';

	/**
	 * Mark selected correspondence as in error - deactivate and lock. All recipient letters will be marked in error too
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.markInError()
	 *
	 * assetXeVisitCorrespondence: AssetXeVisitCorrespondence
	 * returns: VisitCorrespondenceRequest
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespondence.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';

	export const markInError = (assetXeVisitCorrespondence, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/markInError';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeVisitCorrespondence;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespondence.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';

	/**
	 * Query mailing information
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doProcessDashboardMail()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.processDashboardMail()
	 *
	 * visitCorrespondenceDBProcessMailRequest: VisitCorrespondenceDBProcessMailRequest
	 * returns: VisitCorrespondenceProcessFinalRequest
	 */
	import processDashboardMailRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDBProcessMailRequest.json';
	import processDashboardMailResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalRequest.json';

	export const processDashboardMail = (visitCorrespondenceDBProcessMailRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/patient/processDashboardMail';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceDBProcessMailRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'processDashboardMail',
			requestSchema: processDashboardMailRequestSchema,
			responseSchema: processDashboardMailResponseSchema
		});
	};

	processDashboardMail.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDBProcessMailRequest.json';
	processDashboardMail.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalRequest.json';

	/**
	 * Generate the pdf to be sent to each recipient. Letter status goes from FINAL to GENERATED unless waiting on translation
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doProcessFinal()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.processFinal()
	 *
	 * visitCorrespondenceProcessFinalRequest: VisitCorrespondenceProcessFinalRequest
	 * returns: VisitCorrespondenceProcessFinalResponse
	 */
	import processFinalRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalRequest.json';
	import processFinalResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalResponse.json';

	export const processFinal = (visitCorrespondenceProcessFinalRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/patient/processFinal';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceProcessFinalRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'processFinal',
			requestSchema: processFinalRequestSchema,
			responseSchema: processFinalResponseSchema
		});
	};

	processFinal.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalRequest.json';
	processFinal.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalResponse.json';

	/**
	 * Creates new visit correspondence
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doRecordDashboardMail()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.recordDashboardMail()
	 *
	 * visitCorrespondenceCreateSimpleRequest: VisitCorrespondenceCreateSimpleRequest
	 * returns: VisitCorrespondenceProcessFinalRequest
	 */
	import recordDashboardMailRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest.json';
	import recordDashboardMailResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalRequest.json';

	export const recordDashboardMail = (visitCorrespondenceCreateSimpleRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/patient/recordDashboardMail';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceCreateSimpleRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'recordDashboardMail',
			requestSchema: recordDashboardMailRequestSchema,
			responseSchema: recordDashboardMailResponseSchema
		});
	};

	recordDashboardMail.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceCreateSimpleRequest.json';
	recordDashboardMail.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceProcessFinalRequest.json';

	/**
	 * Create/update visit correspondence with specified status. Do any additional actions appropriate to status.
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doSaveAsStatus()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.saveAsStatus()
	 *
	 * visitCorrespondenceEnter: VisitCorrespondenceEnter
	 * returns: VisitCorrespondenceDetail
	 */
	import saveAsStatusRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter.json';
	import saveAsStatusResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail.json';

	export const saveAsStatus = (visitCorrespondenceEnter, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/status';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceEnter;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'saveAsStatus',
			requestSchema: saveAsStatusRequestSchema,
			responseSchema: saveAsStatusResponseSchema
		});
	};

	saveAsStatus.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceEnter.json';
	saveAsStatus.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceDetail.json';

	/**
	 * Test default Letter configuration
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceHandler.doTestDefaultLetterConfig()
	 * @see com.thrasys.xnet.erp.resthandler.correspondence.CorrespondenceService.testDefaultLetterConfig()
	 *
	 * visitCorrespondenceRequest: VisitCorrespondenceRequest
	 * returns: VisitCorrespondenceRequest
	 */
	import testDefaultLetterConfigRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
	import testDefaultLetterConfigResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';

	export const testDefaultLetterConfig = (visitCorrespondenceRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/testDefaultLetterConfig';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondences-svc',
			action: 'testDefaultLetterConfig',
			requestSchema: testDefaultLetterConfigRequestSchema,
			responseSchema: testDefaultLetterConfigResponseSchema
		});
	};

	testDefaultLetterConfig.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
	testDefaultLetterConfig.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorrespondence.VisitCorrespondenceRequest.json';
