/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Define/Record/Generate correspondence about a patient to themselves and their providers.
 */

	/**
	 * get list of visit correspondences by recipients
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.browse()
	 *
	 * correspondenceInfoId: number[]
	 * statusId: string[]
	 * deliveryStatus: string
	 * letterStatus: string[]
	 * letterType: string
	 * sendMethod: string
	 * generatedDateTimeMin: Date
	 * generatedDateTimeMax: Date
	 * modifiedDateTimeMax: Date
	 * modifiedDateTimeMin: Date
	 * active: boolean
	 * isInError: boolean
	 * isMoved: boolean
	 * isSent: boolean
	 * isPrinted: boolean
	 * isAssignedToBatch: boolean
	 * isTranslationReq: boolean
	 * reportConfig1: string
	 * visitCorrespondenceId: number
	 * visitCorrespRecipId: number
	 * programId: string
	 * ipid: number
	 * ivid: number
	 * returns: VisitCorrespondenceRecipDetail
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail.json';

	export const browse = ({correspondenceInfoId, statusId, deliveryStatus, letterStatus, letterType, sendMethod, generatedDateTimeMin, generatedDateTimeMax, modifiedDateTimeMax, modifiedDateTimeMin, active, isInError, isMoved, isSent, isPrinted, isAssignedToBatch, isTranslationReq, reportConfig1, visitCorrespondenceId, visitCorrespRecipId, programId, ipid, ivid, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/list';

		const params = {
			correspondenceInfoId,
			statusId,
			deliveryStatus,
			letterStatus,
			letterType,
			sendMethod,
			generatedDateTimeMin,
			generatedDateTimeMax,
			modifiedDateTimeMax,
			modifiedDateTimeMin,
			active,
			isInError,
			isMoved,
			isSent,
			isPrinted,
			isAssignedToBatch,
			isTranslationReq,
			reportConfig1,
			visitCorrespondenceId,
			visitCorrespRecipId,
			programId,
			ipid,
			ivid
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-recipients-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail.json';

	/**
	 * Bulk update recipient(s) for Status, IsSent (SentDateTime), Batch name, DeliveryStatus (any or all). VisitCorrespRecipID is an array or a piped list in index 0
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doBulkUpdate()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.bulkUpdate()
	 *
	 * visitCorrespRecipBulkUpdateRequest: VisitCorrespRecipBulkUpdateRequest
	 * returns: AssetXeVisitCorrespRecip
	 */
	import bulkUpdateRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespRecipBulkUpdateRequest.json';
	import bulkUpdateResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	export const bulkUpdate = (visitCorrespRecipBulkUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/bulkUpdate';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespRecipBulkUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-recipients-svc',
			action: 'bulkUpdate',
			requestSchema: bulkUpdateRequestSchema,
			responseSchema: bulkUpdateResponseSchema
		});
	};

	bulkUpdate.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespRecipBulkUpdateRequest.json';
	bulkUpdate.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	/**
	 * generate UploadTranslatedFileID PDF, runs slowly. Should not be called by UI. If Auto Generate, the call processFinal
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doConvertUploadToPDF()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.convertUploadToPDF()
	 *
	 * returns: AssetXeVisitCorrespRecip
	 */
	import convertUploadToPDFResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	export const convertUploadToPDF = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/translatedFilePDF';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-recipients-svc',
			action: 'convertUploadToPDF',
			responseSchema: convertUploadToPDFResponseSchema
		});
	};

	convertUploadToPDF.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	/**
	 * get detail of visit correspondences for VisitCorrespRecipID
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.getDetails()
	 *
	 * visitCorrespRecipId: number
	 * visitCorrespondenceId: number
	 * returns: VisitCorrespondenceRecipDetail
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail.json';

	export const getDetails = ({visitCorrespRecipId, visitCorrespondenceId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/details/' + visitCorrespRecipId;

		const params = {
			visitCorrespondenceId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-recipients-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipDetail.json';

	/**
	 * recipient record fax status
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doGetFaxStatus()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.getFaxStatus()
	 *
	 * returns: VisitCorrespondenceRecipResponse
	 */
	import getFaxStatusResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipResponse.json';

	export const getFaxStatus = ({xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/status';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-recipients-svc',
			action: 'getFaxStatus',
			responseSchema: getFaxStatusResponseSchema
		});
	};

	getFaxStatus.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipResponse.json';

	/**
	 * produce manifest of letters to 3rd party processor using DashboardID as Layout. Update BatchID,IsMoved, and VendorExportDate for recod
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doGetManifest()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.getManifest()
	 *
	 * visitCorrespondenceRecipManifestReq: VisitCorrespondenceRecipManifestReq
	 * returns: VisitCorrespondenceRecipResponse
	 */
	import getManifestRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipManifestReq.json';
	import getManifestResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipResponse.json';

	export const getManifest = (visitCorrespondenceRecipManifestReq, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/manifest';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceRecipManifestReq;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-recipients-svc',
			action: 'getManifest',
			requestSchema: getManifestRequestSchema,
			responseSchema: getManifestResponseSchema
		});
	};

	getManifest.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipManifestReq.json';
	getManifest.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipResponse.json';

	/**
	 * get translation tokens for VisitCorrespRecipID
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doGetTranslationTokens()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.getTranslationTokens()
	 *
	 * visitCorrespRecipId: number
	 * visitCorrespondenceId: number
	 * returns: VisitCorrespondenceRecipTokens
	 */
	import getTranslationTokensResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipTokens.json';

	export const getTranslationTokens = ({visitCorrespRecipId, visitCorrespondenceId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/translationTokens/' + visitCorrespRecipId;

		const params = {
			visitCorrespondenceId
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-correspondence-recipients-svc',
			action: 'getTranslationTokens',
			responseSchema: getTranslationTokensResponseSchema
		});
	};

	getTranslationTokens.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipTokens.json';

	/**
	 * Mark recipient(s) as in error. VisitCorrespRecipID is an array or a piped list in index 0
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.markInError()
	 *
	 * visitCorrespRecipUpdateRequest: VisitCorrespRecipUpdateRequest
	 * returns: AssetXeVisitCorrespRecip
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespRecipUpdateRequest.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	export const markInError = (visitCorrespRecipUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/markInError';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespRecipUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-recipients-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespRecipUpdateRequest.json';
	markInError.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	/**
	 * Resend the correspondence as MAIL. Clone recipient record. If is FAX, then add a mailing coversheet. If address is in request, send it. update printcount on original.
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doReMail()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.reMail()
	 *
	 * assetXeVisitCorrespRecip: AssetXeVisitCorrespRecip
	 * returns: VisitCorrespondenceRecipResponse
	 */
	import reMailRequestSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';
	import reMailResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipResponse.json';

	export const reMail = (assetXeVisitCorrespRecip, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/remail';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = assetXeVisitCorrespRecip;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-recipients-svc',
			action: 'reMail',
			requestSchema: reMailRequestSchema,
			responseSchema: reMailResponseSchema
		});
	};

	reMail.requestSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';
	reMail.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipResponse.json';

	/**
	 * update visit correspondence recipient with UploadTranslatedFileID, then generate the recipient letter
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doSaveTranslatedFile()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.saveTranslatedFile()
	 *
	 * visitCorrespRecipTranslateRequest: VisitCorrespRecipTranslateRequest
	 * returns: AssetXeVisitCorrespRecip
	 */
	import saveTranslatedFileRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespRecipTranslateRequest.json';
	import saveTranslatedFileResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	export const saveTranslatedFile = (visitCorrespRecipTranslateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/translatedFile';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespRecipTranslateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-recipients-svc',
			action: 'saveTranslatedFile',
			requestSchema: saveTranslatedFileRequestSchema,
			responseSchema: saveTranslatedFileResponseSchema
		});
	};

	saveTranslatedFile.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespRecipTranslateRequest.json';
	saveTranslatedFile.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	/**
	 * update a recipient record with delivery maintenance details
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doUpdateDelivery()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.updateDelivery()
	 *
	 * visitCorrespRecipUpdateRequest: VisitCorrespRecipUpdateRequest
	 * returns: AssetXeVisitCorrespRecip
	 */
	import updateDeliveryRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespRecipUpdateRequest.json';
	import updateDeliveryResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	export const updateDelivery = (visitCorrespRecipUpdateRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/deliveryRecord';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespRecipUpdateRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-recipients-svc',
			action: 'updateDelivery',
			requestSchema: updateDeliveryRequestSchema,
			responseSchema: updateDeliveryResponseSchema
		});
	};

	updateDelivery.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespRecipUpdateRequest.json';
	updateDelivery.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeVisitCorrespRecip.json';

	/**
	 * update translation tokens for VisitCorrespRecipID
	 * @see com.thrasys.xnet.erp.correspondence.handler.CorrespondenceRecipientHandler.doUpdateTranslationTokens()
	 * @see com.thrasys.xnet.erp.resthandler.correspondencerecipient.CorrespondenceRecipientService.updateTranslationTokens()
	 *
	 * visitCorrespRecipId: number
	 * visitCorrespondenceRecipTokens: VisitCorrespondenceRecipTokens
	 * returns: VisitCorrespondenceRecipTokens
	 */
	import updateTranslationTokensRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipTokens.json';
	import updateTranslationTokensResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipTokens.json';

	export const updateTranslationTokens = (visitCorrespondenceRecipTokens, {visitCorrespRecipId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/correspondence/patient/recipient/translationTokens/' + visitCorrespRecipId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = visitCorrespondenceRecipTokens;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-correspondence-recipients-svc',
			action: 'updateTranslationTokens',
			requestSchema: updateTranslationTokensRequestSchema,
			responseSchema: updateTranslationTokensResponseSchema
		});
	};

	updateTranslationTokens.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipTokens.json';
	updateTranslationTokens.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.visitcorresprecip.VisitCorrespondenceRecipTokens.json';
