/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Record/Update phone contact with patient.
 */

	/**
	 * Request a list of matching call logs
	 * @see com.thrasys.xnet.erp.patient.handler.CallLogHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.calllog.CallLogService.browse()
	 *
	 * xeCallLogBrowseQuery: XeCallLogBrowseQuery
	 * returns: FactXeCallLog
	 */
	import browseRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.XeCallLogBrowseQuery.json';
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog.json';

	export const browse = (xeCallLogBrowseQuery, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/calls/browse';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = xeCallLogBrowseQuery;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-call-logs-svc',
			action: 'browse',
			requestSchema: browseRequestSchema,
			responseSchema: browseResponseSchema
		});
	};

	browse.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.XeCallLogBrowseQuery.json';
	browse.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog.json';

	/**
	 * Update call log entry as inactive or mark in error
	 * @see com.thrasys.xnet.erp.patient.handler.CallLogHandler.doDelete()
	 * @see com.thrasys.xnet.erp.resthandler.calllog.CallLogService.delete()
	 *
	 * callLogId: number
	 * addCallLogRequest: AddCallLogRequest
	 * returns: XeDocumentResponse
	 */
	import doDeleteRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	import doDeleteResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const doDelete = (addCallLogRequest, {callLogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/calls/deactivate/' + callLogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addCallLogRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-call-logs-svc',
			action: 'doDelete',
			requestSchema: doDeleteRequestSchema,
			responseSchema: doDeleteResponseSchema
		});
	};

	doDelete.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	doDelete.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	/**
	 * generic call update
	 * @see com.thrasys.xnet.erp.patient.handler.CallLogHandler.doEnter()
	 * @see com.thrasys.xnet.erp.resthandler.calllog.CallLogService.enter()
	 *
	 * addCallLogRequest: AddCallLogRequest
	 * returns: AddCallLogResponse
	 */
	import enterRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	import enterResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogResponse.json';

	export const enter = (addCallLogRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/calls/enter';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addCallLogRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-call-logs-svc',
			action: 'enter',
			requestSchema: enterRequestSchema,
			responseSchema: enterResponseSchema
		});
	};

	enter.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	enter.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogResponse.json';

	/**
	 * Get detailed information of a call log by CallLogID
	 * @see com.thrasys.xnet.erp.patient.handler.CallLogHandler.doGetDetails()
	 * @see com.thrasys.xnet.erp.resthandler.calllog.CallLogService.getDetails()
	 *
	 * callLogId: number
	 * returns: FactXeCallLog
	 */
	import getDetailsResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog.json';

	export const getDetails = ({callLogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/calls/getDetails/' + callLogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-call-logs-svc',
			action: 'getDetails',
			responseSchema: getDetailsResponseSchema
		});
	};

	getDetails.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog.json';

	/**
	 * generic enter call log insert
	 * @see com.thrasys.xnet.erp.patient.handler.CallLogHandler.doInsert()
	 * @see com.thrasys.xnet.erp.resthandler.calllog.CallLogService.insert()
	 *
	 * addCallLogRequest: AddCallLogRequest
	 * returns: AddCallLogResponse
	 */
	import insertRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	import insertResponseSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogResponse.json';

	export const insert = (addCallLogRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/calls';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addCallLogRequest;

		return makeRequest({
			method: 'POST',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-call-logs-svc',
			action: 'insert',
			requestSchema: insertRequestSchema,
			responseSchema: insertResponseSchema
		});
	};

	insert.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	insert.responseSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogResponse.json';

	/**
	 * update the IVID of  CallLogID to the IVID in the message
	 * @see com.thrasys.xnet.erp.patient.handler.CallLogHandler.doLink()
	 * @see com.thrasys.xnet.erp.resthandler.calllog.CallLogService.link()
	 *
	 * addCallLogRequest: AddCallLogRequest
	 * returns: AssetXeCallLog
	 */
	import linkRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	import linkResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeCallLog.json';

	export const link = (addCallLogRequest, {xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/calls/relink';

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addCallLogRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-call-logs-svc',
			action: 'link',
			requestSchema: linkRequestSchema,
			responseSchema: linkResponseSchema
		});
	};

	link.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	link.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeCallLog.json';

	/**
	 * Update call log entry as inactive or mark in error
	 * @see com.thrasys.xnet.erp.patient.handler.CallLogHandler.doMarkInError()
	 * @see com.thrasys.xnet.erp.resthandler.calllog.CallLogService.markInError()
	 *
	 * callLogId: number
	 * addCallLogRequest: AddCallLogRequest
	 * returns: XeDocumentResponse
	 */
	import markInErrorRequestSchema from '../../schemas/com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	import markInErrorResponseSchema from '../../schemas/com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';

	export const markInError = (addCallLogRequest, {callLogId, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/outreach/calls/markInError/' + callLogId;

		const params = {
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		const body = addCallLogRequest;

		return makeRequest({
			method: 'PUT',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			body,
			service: 'xe-call-logs-svc',
			action: 'markInError',
			requestSchema: markInErrorRequestSchema,
			responseSchema: markInErrorResponseSchema
		});
	};

	markInError.requestSchema = 'com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogRequest.json';
	markInError.responseSchema = 'com.thrasys.xnet.xmlobjects.XeDocumentResponse.json';
