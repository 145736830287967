/**
 * Generated Code Do Not modify, changes will be overwritten on next build
 *
 * Manage and maintain health plan payer records based on asset Xg/Xa/XeHealhtPlanPayer
 */

	/**
	 * Search health plan payers by name
	 * @see com.thrasys.xnet.erp.billing.handler.HealthPlanPayerHandler.doBrowse()
	 * @see com.thrasys.xnet.erp.resthandler.healthplanpayer.HealthPlanPayerService.browse()
	 *
	 * name: string
	 * healthPlanPayerId: string
	 * active: boolean
	 * returns: AssetXeHealthPlanPayer
	 */
	import browseResponseSchema from '../../schemas/com.thrasys.xnet.erp.assets.AssetXeHealthPlanPayer.json';

	export const browse = ({name, healthPlanPayerId, active, xxsid}, makeRequest) => {
		const theRequestUrl = '/rest/billing/config/healthPlanPayer/browse';

		const params = {
			name,
			healthPlanPayerId,
			active
		};

		const headers = {
			'xe-xxsid': xxsid
		};

		return makeRequest({
			method: 'GET',
			responseType: 'json',
			url: theRequestUrl,
			params,
			headers,
			service: 'xe-health-plan-payers-svc',
			action: 'browse',
			responseSchema: browseResponseSchema
		});
	};

	browse.responseSchema = 'com.thrasys.xnet.erp.assets.AssetXeHealthPlanPayer.json';
